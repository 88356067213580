export const tableOption = {
  searchMenuSpan: 6,
  columnBtn: false,
  border: true,
  selection: true,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menuAlign: 'center',
  menuWidth: 350,
  align: 'center',
  refreshBtn: true,
  searchSize: 'mini',
  addBtn: false,
  editBtn: false,
  delBtn: false,
  viewBtn: false,
  props: {
    label: 'label',
    value: 'value'
  },
  column: [{
    label: '园区编号',
    prop: 'areaId',
    search: true
  }, {
    label: '会议室编号',
    prop: 'roomId',
    search: true
  }, {
    label: '月份',
    prop: 'roomMonth'
  }, {
    label: '日期',
    prop: 'roomDate'
  }, {
    label: '时间编号',
    prop: 'timeId'
  }, {
    label: '开始时间',
    prop: 'beginTime'
  }, {
    label: '结束时间',
    prop: 'endTime'
  }, {
    label: '状态',
    prop: 'status',
    type: 'select',
    search: true,
    dicData: [
      {
        label: '待预定',
        value: 0
      }, {
        label: '已预定',
        value: 1
      }
    ]
  }, {
    label: '预定人',
    prop: 'bookUserId'
  }, {
    label: '预定时间',
    prop: 'updateTime'
  }, {
    label: '备注',
    prop: 'remark'
  }]
}
